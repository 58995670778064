import React, { Component } from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import Img from 'gatsby-image'
import {
  Row,
  Col,
  Gap,
  Text,
  Section,
  Title,
  Button,
  VH,
  HpTop,
  Fill,
  BusinessTripSection,
  HopSection,
  BeachSection,
  SEO,
} from '../components'
import { up, addResponsivity } from '../lib/styles'

import multicopterImageSrc from '../data/images/usage/multicopter.png'
import zuriImageSrc from '../data/images/usage/zuri-uses-icon.png'
import planeImageSrc from '../data/images/usage/plane.png'

const PlaneImageWrapper = styled.div`
  /* height: 160px; */
  height: 240px;
  display: flex;
  align-items: center;

  justify-content: center;
  img {
    max-width: 100%;
  }
`

const GapM = () => <Gap gap="60px" mobileGap="40px" bp="tablet" />
const GapL = () => <Gap gap="80px" mobileGap="40px" bp="tablet" />

const UsesTitle = styled(Title.Thin).attrs({})`
  text-transform: uppercase;
  text-align: center;
  margin: 100px auto 40px auto;
`
const CONTENT_WIDTH = '1024px'

const TextContent = ({ children }) => (
  <Row justifyContent="center">
    <Col
      maxWidth={CONTENT_WIDTH}
      mobileMaxWidth="600px"
      bp="tablet"
      alignItems="center"
    >
      <Text textAlign="justify" mobileTextAlign="left">
        {children}
      </Text>
    </Col>
  </Row>
)

const ImagesBlock = ({ image1, image2, image3 }) => (
  <Row
    justifyContent="space-evenly"
    mobileJustifyContent="center"
    alignItems="flex-end"
    mobileAlignItems="center"
    width="100%"
    margin="auto"
    mobileFlexDirection="column"
  >
    <Col width="100%">
      <Img fluid={image1.image.fluid} />
    </Col>
    <Gap gap="16px" />
    <Col width="100%">
      <Img fluid={image2.image.fluid} />
    </Col>
    <Gap gap="16px" />
    <Col width="100%">
      <Img fluid={image3.image.fluid} />
    </Col>
  </Row>
)

class Usage extends Component {
  render() {
    const {
      location: { pathname },
      data: {
        businessTripImage,
        hopImage,
        beachImage,
        cargo1Image,
        cargo2Image,
        cargo3Image,
        sar1Image,
        sar2Image,
        sar3Image,
      },
    } = this.props
    return (
      <>
        <SEO
          title="Uses | Zuri"
          description="We are developing a modern private aircraft with vertical takeoff and landing. This will allow you to affordably travel from city to city at distances of up to 700 km apart."
          pathname={pathname}
        />
        <Gap.Top />
        <BusinessTripSection bgImage={businessTripImage} />
        <Gap gap="150px" mobileGap="0" bp="tablet" />
        <Gap gap="50px" showBelow={1380} />
        <Gap gap="50px" showBelow={1140} />
        <HopSection bgImage={hopImage} />
        <Gap gap="100px" mobileGap="0" />
        <BeachSection bgImage={beachImage} />

        <Section.Simple>
          <UsesTitle>Cargo</UsesTitle>

          <ImagesBlock
            image1={cargo1Image}
            image2={cargo2Image}
            image3={cargo3Image}
          />
          <GapM />
        </Section.Simple>
        <Section>
          <TextContent>
            <Text.Black>CARGO. </Text.Black>
            The Zuri VTOL aircraft is ideal for cargo transport due to its
            ability to take off and land in confined spaces, eliminating the
            need for runways. It can easily access remote or urban areas,
            ensuring quicker loading and unloading. Its advanced propulsion
            system offers reliable, energy-efficient performance.
          </TextContent>
        </Section>
        <Section.Simple>
          <UsesTitle>Sar and Hems</UsesTitle>

          <ImagesBlock
            image1={sar1Image}
            image2={sar2Image}
            image3={sar3Image}
          />
          <GapM />
        </Section.Simple>
        <Section>
          <TextContent>
            <Text.Black>SAR AND HEMS. </Text.Black>
            Zuri is ideal for SAR (Search&Rescue) and Medevac (Medical
            evacuation) missions due to its vertical take-off and landing
            capabilities, enabling operation in remote or confined areas without
            runways. Its agility and speed allow rapid emergency response. The
            customizable cabin accommodates lying patients and medical
            equipment. Its efficient propulsion system ensures low operational
            costs, making it viable for widespread use.
            <br />
            <br />
            Advanced avionics provide precise navigation and communication,
            essential for SAR and Medevac tasks.
          </TextContent>
          <Gap gap="100px" mobileGap="40px" bp="tablet" />
        </Section>

        <Section background="#000000" width="1600px">
          <Col alignItems="center">
            <Gap gap="100px" mobileGap="60px" bp="tablet" />
            <Title.Usage mobileFontSize="26px">TYPICAL USE CASES</Title.Usage>
            <Gap gap="60px" mobileGap="0" bp="tablet" />
            <Row
              justifyContent="space-evenly"
              width="100%"
              wrap="nowrap"
              mobileFlexDirection="column"
            >
              <Col basis="33%">
                <PlaneImageWrapper>
                  <img src={multicopterImageSrc} alt="Multicopter" />
                </PlaneImageWrapper>
                <Title.UsagePlaneType white>MULTICOPTERS</Title.UsagePlaneType>
                <Gap gap="15px" />
                <Text.UseCaseDescription>
                  Urban Air Mobility, short range
                </Text.UseCaseDescription>
              </Col>
              <Gap gap="10px" mobileGap="80px" />
              <Col basis="33%">
                <PlaneImageWrapper>
                  <img src={zuriImageSrc} alt="Zuri" />
                </PlaneImageWrapper>
                <Title.UsagePlaneType white>ZURI</Title.UsagePlaneType>
                <Gap gap="15px" />
                <Text.UseCaseDescription>
                  Between cities, medium range
                </Text.UseCaseDescription>
              </Col>
              <Gap gap="10px" mobileGap="80px" />
              <Col basis="33%">
                <PlaneImageWrapper>
                  <img src={planeImageSrc} alt="Airliner" />
                </PlaneImageWrapper>
                <Title.UsagePlaneType white>AIRLINERS</Title.UsagePlaneType>
                <Gap gap="15px" />
                <Text.UseCaseDescription>
                  Long range, even intercontinental
                </Text.UseCaseDescription>
              </Col>
            </Row>
          </Col>
          <Gap gap="150px" mobileGap="50px" />
        </Section>
      </>
    )
  }
}

export default Usage

export const pageQuery = graphql`
  fragment UsesImage on File {
    image: childImageSharp {
      fluid(maxWidth: 629, quality: 90) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }

  query {
    cargo1Image: file(relativePath: { eq: "images/usage/cargo-1.jpg" }) {
      ...UsesImage
    }
    cargo2Image: file(relativePath: { eq: "images/usage/cargo-2.jpg" }) {
      ...UsesImage
    }
    cargo3Image: file(relativePath: { eq: "images/usage/cargo-3.jpg" }) {
      ...UsesImage
    }

    sar1Image: file(relativePath: { eq: "images/usage/sar-1.jpg" }) {
      ...UsesImage
    }
    sar2Image: file(relativePath: { eq: "images/usage/sar-2.jpg" }) {
      ...UsesImage
    }
    sar3Image: file(relativePath: { eq: "images/usage/sar-3.jpg" }) {
      ...UsesImage
    }

    businessTripImage: file(relativePath: { eq: "images/usage/bg1.jpg" }) {
      ...FullHdImage
    }
    hopImage: file(relativePath: { eq: "images/usage/bg2.jpg" }) {
      ...FullHdImage
    }
    beachImage: file(relativePath: { eq: "images/usage/bg3.jpg" }) {
      ...FullHdImage
    }
  }
`
